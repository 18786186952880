import React from 'react';

const ErrorTable = ({ data }) => {
  return (
    <table
      style={{
        borderCollapse: 'collapse',
        width: '100%',
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              padding: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#ddd',
              backgroundColor: '#f2f2f2',
              fontWeight: 'bold',
            }}
          >
            Header Name
          </th>
          <th
            style={{
              padding: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#ddd',
              backgroundColor: '#f2f2f2',
              fontWeight: 'bold',
            }}
          >
            Column Index
          </th>
          <th
            style={{
              padding: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#ddd',
              backgroundColor: '#f2f2f2',
              fontWeight: 'bold',
            }}
          >
            Row Index
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td
              style={{
                padding: '8px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#ddd',
              }}
            >
              {item.headerName}
            </td>
            <td
              style={{
                padding: '8px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#ddd',
              }}
            >
              {item.columnIndex}
            </td>
            <td
              style={{
                padding: '8px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#ddd',
              }}
            >
              {item.rowIndex}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ErrorTable;
