import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IErpaxa } from 'app/shared/model/erpaxa.model';
import { getEntity, updateEntity, createEntity, reset } from './erpaxa.reducer';

export const ErpaxaUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const erpaxaEntity = useAppSelector(state => state.erpaxa.entity);
  const loading = useAppSelector(state => state.erpaxa.loading);
  const updating = useAppSelector(state => state.erpaxa.updating);
  const updateSuccess = useAppSelector(state => state.erpaxa.updateSuccess);

  const handleClose = () => {
    navigate('/erpaxa' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...erpaxaEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...erpaxaEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="oodoExcelCreatorApp.erpaxa.home.createOrEditLabel" data-cy="ErpaxaCreateUpdateHeading">
            <Translate contentKey="oodoExcelCreatorApp.erpaxa.home.createOrEditLabel">Create or edit a Erpaxa</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="erpaxa-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.cardType')}
                id="erpaxa-cardType"
                name="cardType"
                data-cy="cardType"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.oldMembershipNumber')}
                id="erpaxa-oldMembershipNumber"
                name="oldMembershipNumber"
                data-cy="oldMembershipNumber"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.name')}
                id="erpaxa-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.address')}
                id="erpaxa-address"
                name="address"
                data-cy="address"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.emirate')}
                id="erpaxa-emirate"
                name="emirate"
                data-cy="emirate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.country')}
                id="erpaxa-country"
                name="country"
                data-cy="country"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.mobile')}
                id="erpaxa-mobile"
                name="mobile"
                data-cy="mobile"
                type="text"
              />
              <ValidatedField label={translate('oodoExcelCreatorApp.erpaxa.zip')} id="erpaxa-zip" name="zip" data-cy="zip" type="text" />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.regionCode')}
                id="erpaxa-regionCode"
                name="regionCode"
                data-cy="regionCode"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleType')}
                id="erpaxa-vehicleType"
                name="vehicleType"
                data-cy="vehicleType"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleModel')}
                id="erpaxa-vehicleModel"
                name="vehicleModel"
                data-cy="vehicleModel"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleMfgYear')}
                id="erpaxa-vehicleMfgYear"
                name="vehicleMfgYear"
                data-cy="vehicleMfgYear"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehiclePlate')}
                id="erpaxa-vehiclePlate"
                name="vehiclePlate"
                data-cy="vehiclePlate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.mailRef')}
                id="erpaxa-mailRef"
                name="mailRef"
                data-cy="mailRef"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleRegCode')}
                id="erpaxa-vehicleRegCode"
                name="vehicleRegCode"
                data-cy="vehicleRegCode"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleChasisNo')}
                id="erpaxa-vehicleChasisNo"
                name="vehicleChasisNo"
                data-cy="vehicleChasisNo"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.policyNo')}
                id="erpaxa-policyNo"
                name="policyNo"
                data-cy="policyNo"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleRegCountry')}
                id="erpaxa-vehicleRegCountry"
                name="vehicleRegCountry"
                data-cy="vehicleRegCountry"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.vehicleEmirate')}
                id="erpaxa-vehicleEmirate"
                name="vehicleEmirate"
                data-cy="vehicleEmirate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.deliveryDate')}
                id="erpaxa-deliveryDate"
                name="deliveryDate"
                data-cy="deliveryDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.invoiceRefDate')}
                id="erpaxa-invoiceRefDate"
                name="invoiceRefDate"
                data-cy="invoiceRefDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.memberExpiryDate')}
                id="erpaxa-memberExpiryDate"
                name="memberExpiryDate"
                data-cy="memberExpiryDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.memberActivateDate')}
                id="erpaxa-memberActivateDate"
                name="memberActivateDate"
                data-cy="memberActivateDate"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.remarks')}
                id="erpaxa-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.packageId')}
                id="erpaxa-packageId"
                name="packageId"
                data-cy="packageId"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.customerCode')}
                id="erpaxa-customerCode"
                name="customerCode"
                data-cy="customerCode"
                type="text"
              />
              <ValidatedField
                label={translate('oodoExcelCreatorApp.erpaxa.categoryCode')}
                id="erpaxa-categoryCode"
                name="categoryCode"
                data-cy="categoryCode"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/erpaxa" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ErpaxaUpdate;
