import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './erpaxa.reducer';

export const ErpaxaDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const erpaxaEntity = useAppSelector(state => state.erpaxa.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="erpaxaDetailsHeading">
          <Translate contentKey="oodoExcelCreatorApp.erpaxa.detail.title">Erpaxa</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.id}</dd>
          <dt>
            <span id="cardType">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.cardType">Card Type</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.cardType}</dd>
          <dt>
            <span id="oldMembershipNumber">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.oldMembershipNumber">Old Membership Number</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.oldMembershipNumber}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.name">Name</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.name}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.address">Address</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.address}</dd>
          <dt>
            <span id="emirate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.emirate">Emirate</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.emirate}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.country">Country</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.country}</dd>
          <dt>
            <span id="mobile">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.mobile">Mobile</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.mobile}</dd>
          <dt>
            <span id="zip">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.zip">Zip</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.zip}</dd>
          <dt>
            <span id="regionCode">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.regionCode">Region Code</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.regionCode}</dd>
          <dt>
            <span id="vehicleType">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleType">Vehicle Type</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleType}</dd>
          <dt>
            <span id="vehicleModel">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleModel">Vehicle Model</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleModel}</dd>
          <dt>
            <span id="vehicleMfgYear">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleMfgYear">Vehicle Mfg Year</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleMfgYear}</dd>
          <dt>
            <span id="vehiclePlate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehiclePlate">Vehicle Plate</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehiclePlate}</dd>
          <dt>
            <span id="mailRef">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.mailRef">Mail Ref</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.mailRef}</dd>
          <dt>
            <span id="vehicleRegCode">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleRegCode">Vehicle Reg Code</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleRegCode}</dd>
          <dt>
            <span id="vehicleChasisNo">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleChasisNo">Vehicle Chasis No</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleChasisNo}</dd>
          <dt>
            <span id="policyNo">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.policyNo">Policy No</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.policyNo}</dd>
          <dt>
            <span id="vehicleRegCountry">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleRegCountry">Vehicle Reg Country</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleRegCountry}</dd>
          <dt>
            <span id="vehicleEmirate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.vehicleEmirate">Vehicle Emirate</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.vehicleEmirate}</dd>
          <dt>
            <span id="deliveryDate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.deliveryDate">Delivery Date</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.deliveryDate}</dd>
          <dt>
            <span id="invoiceRefDate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.invoiceRefDate">Invoice Ref Date</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.invoiceRefDate}</dd>
          <dt>
            <span id="memberExpiryDate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.memberExpiryDate">Member Expiry Date</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.memberExpiryDate}</dd>
          <dt>
            <span id="memberActivateDate">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.memberActivateDate">Member Activate Date</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.memberActivateDate}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.remarks}</dd>
          <dt>
            <span id="packageId">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.packageId">Package Id</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.packageId}</dd>
          <dt>
            <span id="customerCode">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.customerCode">Customer Code</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.customerCode}</dd>
          <dt>
            <span id="categoryCode">
              <Translate contentKey="oodoExcelCreatorApp.erpaxa.categoryCode">Category Code</Translate>
            </span>
          </dt>
          <dd>{erpaxaEntity.categoryCode}</dd>
        </dl>
        <Button tag={Link} to="/erpaxa" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/erpaxa/${erpaxaEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ErpaxaDetail;
