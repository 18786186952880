export interface IErpaxa {
  id?: number;
  cardType?: string | null;
  oldMembershipNumber?: string | null;
  name?: string | null;
  address?: string | null;
  emirate?: string | null;
  country?: string | null;
  mobile?: string | null;
  zip?: string | null;
  regionCode?: string | null;
  vehicleType?: string | null;
  vehicleModel?: string | null;
  vehicleMfgYear?: string | null;
  vehiclePlate?: string | null;
  mailRef?: string | null;
  vehicleRegCode?: string | null;
  vehicleChasisNo?: string | null;
  policyNo?: string | null;
  vehicleRegCountry?: string | null;
  vehicleEmirate?: string | null;
  deliveryDate?: string | null;
  invoiceRefDate?: string | null;
  memberExpiryDate?: string | null;
  memberActivateDate?: string | null;
  remarks?: string | null;
  packageId?: string | null;
  customerCode?: string | null;
  categoryCode?: string | null;
}

export const defaultValue: Readonly<IErpaxa> = {};
