import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Erpaxa from './erpaxa';
import ErpaxaDetail from './erpaxa-detail';
import ErpaxaUpdate from './erpaxa-update';
import ErpaxaDeleteDialog from './erpaxa-delete-dialog';
import ExcelUpload from './excel-upload';

const ErpaxaRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Erpaxa />} />
    <Route path="new" element={<ErpaxaUpdate />} />
    <Route path="upload" element={<ExcelUpload />} />
    <Route path=":id">
      <Route index element={<ErpaxaDetail />} />
      <Route path="edit" element={<ErpaxaUpdate />} />
      <Route path="delete" element={<ErpaxaDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ErpaxaRoutes;
