const fields = [
  {
    label: 'ID',
    key: 'id',
    alternateMatches: ['id'],
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    example: '12',
  },
  {
    label: 'Card Type',
    key: 'card_type',
    alternateMatches: ['card_type', 'Cover Name', 'Road_Side_Assistance_Type'],
    fieldType: {
      type: 'input',
    },
    example: 'G',
  },
  {
    label: 'Old Membership Number',
    key: 'old_membership_number',
    alternateMatches: ['old_membership_number', 'EVG Policy Number'],
    fieldType: {
      type: 'input',
    },
    example: 'AXAAE30242077',
  },
  {
    label: 'Name',
    key: 'name',
    alternateMatches: [
      'name',
      'first name',
      'POL ASSURED NAME',
      'Insured Name',
      'Customer_Name',
      'ASSURED',
      'CUSTOMER NAME',
      'POL ASSURED NAME',
      'CUSTOMERNAME',
    ],
    fieldType: {
      type: 'input',
    },
    example: 'AL FALASI LUXURY TRA',
  },
  {
    label: 'Address',
    key: 'address',
    alternateMatches: ['address', 'PO_BOX_Emirate', 'ADDR1'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Emirate',
    key: 'emirate',
    alternateMatches: ['emirate', 'Emirates', 'EMIRATE'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Country',
    key: 'country',
    alternateMatches: ['country'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Mobile',
    key: 'mobile',
    alternateMatches: ['mobile', 'phone', 'mobile number', 'MobileNo', 'TEL_NO', 'Mobile No.', 'MOBILE'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Zip',
    key: 'zip',
    alternateMatches: ['zip', 'zip code', 'post code', 'Po_Box'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Region Code',
    key: 'region_code',
    alternateMatches: ['region_code'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Type',
    key: 'vehicle_type',
    alternateMatches: [
      'vehicle_type',
      'Vehicle Usage : ',
      'Veh Make Desc',
      'Vehcile Make',
      'Vehicle Make',
      'Make',
      'VEHICLE (need to split to vehicle model)',
      'Vehicle Make Model (need to split to vehicle model)',
      'Vehicle Make Model (need to split to vehicle model)',
      'VEHICLEMODEL',
      'VM DESC',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Model',
    key: 'vehicle_model',
    alternateMatches: [
      'vehicle_model',
      'VM DESC',
      'Model Desc',
      'Vehcile Model',
      'Vehicle Model',
      'Model',
      'VEHICLE (need to split to vehicle Type)',
      'Vehicle Make Model (need to split to vehicle model)',
      'Vehicle Make Model (need to split to vehicle model)',
      'VEHICLEBRAND',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Manufacture Year',
    key: 'vehicle_mfg_year',
    alternateMatches: [
      'vehicle_mfg_year',
      'VEH YEAR',
      'Year Model',
      'Model Year',
      'Mfg_Year',
      'Mfg Yr',
      'MODEL_YR',
      'Year',
      'YEAR OF MFG',
      'MANUFACTUREYEAR',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Plate',
    key: 'vehicle_plate',
    alternateMatches: [
      'vehicle_plate',
      'VEH REGN NO',
      'Plate No.',
      'Registration_No',
      'Regn No.',
      'REGN_NO',
      'Vehicle Reg No.',
      'REGISTRATION',
      'PLATENO',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Mail Ref',
    key: 'mail_ref',
    alternateMatches: ['mail_ref', 'email', 'mail'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Reg Code',
    key: 'vehicle_reg_code',
    alternateMatches: ['vehicle_reg_code'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Chasis No',
    key: 'vehicle_chasis_no',
    alternateMatches: [
      'vehicle_chasis_no',
      'VEH CHASSIS NO',
      'Chasis No.',
      'Chassis No.',
      'Chassis_No',
      'CHASSIS_NO',
      'Chassis No',
      'CHASSIS NO',
      'CHASSISNUMBER',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Policy No',
    key: 'policy_no',
    alternateMatches: [
      'policy_no',
      'POLICY NUMBER',
      'Fleet Policy No.',
      'Policy No.',
      'Policy_No',
      'Policy  Number',
      'POL_REF_NO',
      'Policy No',
      'POLICYNO',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Reg Country',
    key: 'vehicle_reg_country',
    alternateMatches: ['vehicle_reg_country', 'PLACE OF REGISTRATION', 'Reg_Emirate'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Vehicle Emirate',
    key: 'vehicle_emirate',
    alternateMatches: ['vehicle_emirate'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Delivery Date',
    key: 'delivery_date',
    alternateMatches: ['delivery_date'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Invoice Ref Date',
    key: 'invoice_ref_date',
    alternateMatches: ['invoice_ref_date', 'APP_DT', 'Policy Issuance Date', 'POLICYISSUEDATE'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Member Expiry Date',
    key: 'member_expiry_date',
    alternateMatches: [
      'member_expiry_date',
      'POL TO DT',
      'Veh. Exit Date',
      'Pol Expiry Date',
      'Pol To Dt',
      'POL_TO',
      'Policy Expiry date',
      'POLICYEXPIRYDATE',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Member Activate Date',
    key: 'member_activate_date',
    alternateMatches: [
      'member_activate_date',
      'POL FM DT',
      'Veh. Entry date',
      'Pol Start Date',
      'Policy_Issued_Date',
      'Pol Fm Dt',
      'POL_FM',
      'Policy Start Date',
      'POL FM DT',
      'POLICYSTARTDATE',
    ],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Remarks',
    key: 'remarks',
    alternateMatches: ['remarks'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Package ID',
    key: 'package_id',
    alternateMatches: ['package_id'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Customer Code',
    key: 'customer_code',
    alternateMatches: ['customer_code'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
  {
    label: 'Category Code',
    key: 'category_code',
    alternateMatches: ['category_code'],
    fieldType: {
      type: 'input',
    },
    example: '',
  },
] as const;

export default fields;
